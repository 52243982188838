import React from "react";
import {graphql, useStaticQuery} from "gatsby";

const Interested = ({pageContext}) => {
    const lang = pageContext.locale.path.default;
    const query = useStaticQuery(graphql`
    query {
        menu: markdownRemark (
            frontmatter: {
                name: { eq: "interested-identity" }
            }
        ) {
            frontmatter {
                en {
                    title
                    sales_title
                    sales_button
                    white_paper_title
                    white_paper_button                    
                }
                es {
                    title
                    sales_title
                    sales_button
                    white_paper_title
                    white_paper_button
                }
            }
        }
    }
    `);
    const menu = query.menu.frontmatter[lang];

    return (
        <section className="pt-11 pt-md-11 pb-9"
                 style={{background: 'url(' + require("../../img/bg-cta.jpg").default + ')', backgroundSize: 'cover', position: 'relative', zIndex: '9'}}>
            <div className="container pb-6 pb-md-8">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-offset-2">
                        <h2 className="font-weight-bold mb-1 display-4 spacing text-white">
                            {menu.title}
                        </h2>
                        <br/><br/><br/>

                        <div className="row justify-content-center">
                            <div className="col-xl-6">
                                <div className="card hvr-float mb-3">
                                    <div className="card-body">
                                        <div align="center"/>

                                        <h2 className="font-weight-bold text-black-80 spacing mt-3 mb-4">{menu.sales_title}</h2>

                                        <p>
                                            <a href="#" data-toggle="modal" data-target="#modal-contact-sales"
                                               className="btn btn-primary btn-sm1 mr-2 spacing font-weight-bold mt-3"
                                               style={{width: '100%'}}>
                                                {menu.sales_button}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {lang === 'es' && <div className="col-xl-6">
                                <div className="card hvr-float mb-3">
                                    <div className="card-body">
                                        <div align="center"/>

                                        <h2 className="font-weight-bold text-black-80 spacing mt-3 mb-4">{menu.white_paper_title}</h2>

                                        <p>
                                            <a href="#" data-toggle="modal" data-target="#modal-white-paper" className="btn-primary-outline mr-2 spacing font-weight-bold mt-3"
                                               style={{width: '100%'}}>
                                                {menu.white_paper_button}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>}
                        </div>

                        <br/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Interested
