import React from "react";
import SEO from "../../components/common/seo";
import LayoutThird from "../../components/common/layout-third";
import IdentityNav from "../../components/common/identity-nav";
import Partners from "../../components/common/partners";
import Interested from "../../components/identity/interested";
import ModalWhitePaper from "../../components/modals/modal-white-paper";
import ModalContactSales from "../../components/modals/modal-contact-sales";
import ContactBox from "../../components/common/contact-box";
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import {customElements} from "../../utils/global-functions";
import rehypeRaw from "rehype-raw";

const FuncionalidadesPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiIdentityFuncionalidades.edges[0].node;
    const customElementsTwo = (pClassName = '', ulClassName = '', liClassName = '', h3ClassName = '') => {
        return {
            p: ({node, ...props}) => <p className={pClassName} {...props} />,
            h3: ({node, ...props}) => <h3 className={h3ClassName} {...props} />,
            ul: ({node, ...props}) => <div className="check-list">
                <ul className={ulClassName} {...props} />
            </div>,
            li: ({node, ...props}) => <li className={liClassName} {...props} />,
        }
    }

    return (
        <LayoutThird pageContext={pageContext} cookies={data.cookies} favicon="favicon-ide.png">
            <SEO lang={lang} title={page.seo.title} image={page.seo.image.localFile.publicURL} description={page.seo.meta_description}/>

            <IdentityNav pageContext={pageContext}/>

            <section className="f4 mb-0 content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-12 col-xl-12" data-aos="fade-right">
                            <div className="card accordion mt-10" id="featuresAccordion">
                                <div className="card-body">
                                    <div className="list-group list-group-flush">
                                        <div className="list-group">
                                            <div className="list-group-item">
                                                <div className="d-flex">
                                                    <div className="mr-auto">
                                                        <h1 className="font-weight-bold mt-2 spacing" style={{fontSize: '1.9375rem'}}>
                                                            {page.title}
                                                        </h1>
                                                    </div>

                                                    <div className="icons1 text-primary mb-2">
                                                        <img src={require("../../img/products/ic_settings.svg").default} alt="Settings" style={{width: '3rem', height: '3rem'}}/>
                                                    </div>
                                                </div>
                                            </div>

                                            {page.identity_items.map((item, i) => {
                                                return <div className="list-group-item" key={i}>
                                                    <a className="d-flex text-reset text-decoration-none" data-toggle="collapse" href={`#features-${i}`} role="button"
                                                       aria-expanded="true" aria-controls={`features-${i}`}>
                                                        <div className="mr-auto">
                                                            <span className="mr-4 font-weight-bold text-tecalis spacing font-size-lg">
                                                                {(typeof String.prototype.replaceAll !== "undefined") ? item.title?.replaceAll('????', '👉') : item.title}
                                                            </span>
                                                        </div>

                                                        <span className="collapse-chevron text-muted ml-4">
                                                            <i className="fe fe-lg fe-chevron-down"/>
                                                        </span>
                                                    </a>

                                                    <div className={i === 0 ? 'collapse show' : 'collapse'} id={`features-${i}`} data-parent="#featuresAccordion">
                                                        <div className="py-2 py-mb-6 pr-6 spacing">
                                                            <br/>

                                                            <ReactMarkdown children={item.description}
                                                                           rehypePlugins={[rehypeRaw]}
                                                                           components={customElementsTwo('font-size-lg text-left', 'spacing mb-5 font-size-lg')}/>

                                                            <br/>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-8 py-md-11 bg-grey">
                <div className="container">
                    <div className="row">
                        {page.characteristics.map((item, i) => {
                            return <div className={i === 0 ? "col-12" : "col-12 mt-5"} key={i}>
                                <div className="card shadow-light-lg overflow-hidden text-center text-lg-left" data-aos="fade-right">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="card-body py-7 py-md-9 mt-8 ml-count">

                                                <h2 className="display-1 font-weight-black text-primary">
                                                    <span data-toggle="countup" data-from="0" data-to={item.number} data-aos
                                                          data-aos-id="countup:in">{item.number}</span>{item.symbol}
                                                </h2>

                                                <p className="text-muted mb-6 mb-md-0">
                                                    {item.subtitle}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body py-7 py-md-9">
                                                <div className="icons text-primary mb-2">
                                                    <img src={item.icon.localFile.publicURL} alt={item.name}/>
                                                </div>

                                                <h4 className="font-weight-bold spacing">
                                                    {item.name}
                                                </h4>

                                                <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('text-muted mb-4 spacing')}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </section>

            <section className="pt-8 pt-md-8 border-bottom" id="work">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="row justify-content-around">
                                <div className="col-xl-8">
                                    <div className="tab-content">
                                        <div className="tab-pane fade show active" id="content-1" role="tabpanel">
                                            <div className="position-relative pt-7 mb-md-9 mt-9 border rounded-xl overflow-hidden">
                                                <div className="position-absolute top-0 right-0 bottom-0 left-0 bg-gradient-multicolor1">
                                                    <div className="d-flex" style={{paddingTop: '15px', paddingLeft: '15px'}}>
                                                        <span className="bg-danger rounded-circle" style={{width: '10px', height: '10px'}}/>
                                                        <span className="bg-warning rounded-circle ml-2" style={{width: '10px', height: '10px'}}/>
                                                        <span className="bg-success rounded-circle ml-2" style={{width: '10px', height: '10px'}}/>
                                                    </div>
                                                </div>

                                                <img className="position-relative img-fluid shadow-lg" src={require("../../img/products/identity1a.jpg").default} alt="..."/>
                                            </div>

                                        </div>
                                        <div className="tab-pane fade" id="content-2" role="tabpanel">
                                            <div className="position-relative pt-7 mb-md-9 mt-9 border rounded-xl overflow-hidden">
                                                <div className="position-absolute top-0 right-0 bottom-0 left-0 bg-gradient-multicolor1">
                                                    <div className="d-flex" style={{paddingTop: '15px', paddingLeft: '15px'}}>
                                                        <span className="bg-danger rounded-circle" style={{width: '10px', height: '10px'}}/>
                                                        <span className="bg-warning rounded-circle ml-2" style={{width: '10px', height: '10px'}}/>
                                                        <span className="bg-success rounded-circle ml-2" style={{width: '10px', height: '10px'}}/>
                                                    </div>
                                                </div>

                                                <img className="position-relative img-fluid rounded-left shadow-lg" src={require("../../img/products/identity1b.jpg").default}
                                                     alt="..."/>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="content-3" role="tabpanel">
                                            <div className="position-relative pt-7 mb-md-9 mt-9 border rounded-xl overflow-hidden">
                                                <div className="position-absolute top-0 right-0 bottom-0 left-0 bg-gradient-multicolor1">
                                                    <div className="d-flex" style={{paddingTop: '15px', paddingLeft: '15px'}}>
                                                        <span className="bg-danger rounded-circle" style={{width: '10px', height: '10px'}}/>
                                                        <span className="bg-warning rounded-circle ml-2" style={{width: '10px', height: '10px'}}/>
                                                        <span className="bg-success rounded-circle ml-2" style={{width: '10px', height: '10px'}}/>
                                                    </div>
                                                </div>

                                                <img className="position-relative img-fluid rounded-left shadow-lg" src={require("../../img/products/identity1c.jpg").default}
                                                     alt="..."/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4">
                                    <ul className="nav nav-cards" role="tablist">
                                        {page.steps.map((step, i) => {
                                            return <li data-aos="fade-up" data-aos-delay={step.order * 50} key={i}>
                                                <a className={i === 0 ? "card-nav active" : "card-nav"} data-toggle="tab" href={`#content-${step.order}`} role="tab"
                                                   aria-controls={`content-${step.order}`} aria-selected={i === 0}>
                                                    <div className="card-body-nav">
                                                        <h4 className="font-weight-bold spacing sel-iden"><b>{step.order}.-</b> {step.title}</h4>
                                                        <ReactMarkdown children={step.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-sm spacing sel-iden-p')}/>
                                                    </div>
                                                </a>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/>
                </div>
            </section>

            <section className="py-8 py-md-11 mb-9">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <h2 className="display-4 font-weight-bold spacing">
                                {page.mechanism_title}
                                <br/>
                            </h2>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-12 col-md-5 col-lg-5" data-aos="fade-right">
                            <img src={require('../../img/products/sec-iden.svg').default} width="450" alt="..." className="img-fluid rounded mb-9 mt-10"/>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 offset-lg-1" data-aos="fade-left">
                            {page.mechanism_items.map((item, i) => {
                                return <div className="d-flex">
                                    <div className="badge badge-lg badge-rounded-circle badge-primary-soft mt-1">
                                        <span>{item.order}</span>
                                    </div>

                                    <div className="ml-5 mt-1">
                                        <h3 className="spacing font-weight-bold">
                                            {item.title}
                                        </h3>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-grey pt-8 pb-9 pt-md-11 pb-md-8 border-bottom">
                <div className="container">
                    <h2 align="center" className="display-4 font-weight-bold text-blue spacing">
                        {page.ai_title}
                    </h2>

                    <hr className="hr-sm my-6 my-md-8 border-gray-300"/>

                    <div className="row justify-content-center">
                        <div className="col-xl-12 text-center" data-aos="fade-down">
                            <ReactMarkdown children={page.ai_description}
                                           rehypePlugins={[rehypeRaw]}
                                           components={customElementsTwo('font-size-lg text-gray-700 mb-6 mb-md-6 spacing text-left', '', '', 'font-weight-bold spacing text-left')}/>
                        </div>
                    </div>

                    <div className="row">
                        {page.ai_items.map((item, i) => {
                            return <div className="col-12 col-xl-4 d-flex" data-aos="fade-down" data-aos-delay={(i + 1) * 100}>
                                <div className="card mb-6 mb-xl-0 pt-6 overlay overlay-black overlay-0 bg-cover">
                                    <div className="card-body mt-auto">
                                        <img src={item.icon.localFile.publicURL} className="img-fluid steps-why opacity-number" width="250" alt="1"/>

                                        <h3 className="font-weight-black spacing steps-iden">
                                            {item.title}
                                        </h3>

                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg mb-6 spacing steps-iden1')} />
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </section>

            <Interested pageContext={pageContext}/>

            <Partners pageContext={pageContext}/>

            <ContactBox pageContext={pageContext} product="Identity"/>

            <ModalWhitePaper pageContext={pageContext}/>

            <ModalContactSales pageContext={pageContext} product="Identity"/>

        </LayoutThird>
    )
}

export default FuncionalidadesPage

export const funcionalidadesPageQuery = graphql
    `query($lang: String)
    {
        allStrapiIdentityFuncionalidades ( filter: { locale: { eq: $lang } } )
        {
            edges {
                node {
                    id
                    seo {
                        title
                        meta_description
                        image {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    identity_items {
                        order
                        title
                        description 
                    }
                    characteristics {
                        number
                        symbol
                        name
                        subtitle
                        description
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    steps {
                        order
                        title
                        description 
                    }
                    mechanism_title
                    mechanism_items {
                        order
                        title
                    }
                    ai_title
                    ai_description
                    ai_items {
                        order
                        title
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                        description
                    } 
                }
            }
        }
        cookies: markdownRemark(
            frontmatter : {
            lang: {
                eq: $lang
            }
            name: {
                eq: "cookies"
            }
        }
    )
        {
            frontmatter
            {
                cookiesStart
                cookiesEnd
                linkText
            }
        }
    }
`;
