import React, {useState} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {gtagCustomEvent, useFormInput} from "../../utils/global-functions";

const ModalWhitePaper = ({pageContext}) => {
    const langBase = '/' + pageContext.locale.path.default;
    const name = useFormInput("")
    const email = useFormInput("")
    const conditions = useFormInput("")

    const {executeRecaptcha} = useGoogleReCaptcha()
    const [success, setSuccess] = useState("")
    const [fail, setFail] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!executeRecaptcha) {
            console.error("executeRecaptcha fail");
            return
        }

        console.log(name);

        const token = await executeRecaptcha("homepage")

        const json = {
            "whitePaperName": name.value,
            "whitePaperEmail": email.value,
            "g-recaptcha-response": token
        };
        const data = JSON.stringify(json)
        const whitePaperForm = document.getElementById("white-paper-form")
        const btnSend = document.getElementById("btn-white-paper-send")

        whitePaperForm.style.opacity = "0.5"
        btnSend.innerText = "Enviando..."
        btnSend.disabled = true

        fetch(process.env.SPIRIT_URL + "white-paper-form", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
            .then(response => {
                if (response.ok) return response.json()

                throw new Error("No enviado. No ha sido posible registrar tu mensaje, por favor, contáctanos de otro modo")
            })
            .then(data => {
                whitePaperForm.style.opacity = "1"
                whitePaperForm.reset()
                clearForm()
                btnSend.innerText = "Descargar"
                btnSend.disabled = false
                setSuccess(data.msg)
                setFail("")
                hideAlerts()

                gtagCustomEvent("white-paper.sent", {
                    from: window.location.href,
                    data: json
                })
            })
            .catch(error => {
                whitePaperForm.style.opacity = "1"
                btnSend.innerText = "Descargar"
                btnSend.disabled = false
                setFail(error.message)
                setSuccess("")
                hideAlerts()
            })
    }

    const hideAlerts = () => {
        setTimeout(() => {
            setSuccess("")
            setFail("")
        }, 10000)
    }

    const clearForm = () => {
        name.clearValue()
        email.clearValue()
    }

    return (
        <div className="modal fade" id="modal-white-paper" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="container">
                            <button type="button" className="modal-close1 close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>

                            <div className="row">
                                <div className="col-xl-6">
                                    <h2 className="mb-0 font-weight-bold spacing" id="modalSigninHorizontalTitle">
                                        👉 Iniciar descarga White Paper
                                    </h2>

                                    <br/>

                                    <p className="mb-6  text-muted spacing">
                                        Un último paso antes de comenzar la descarga...
                                    </p>

                                    <form id="white-paper-form" className="mb-6" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="sr-only" htmlFor="whitePaperName">
                                                        Nombre
                                                    </label>
                                                    <input {...name} type="text" className="form-control spacing" id="whitePaperName" name="whitePaperName" placeholder="Nombre"
                                                           required/>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="sr-only" htmlFor="whitePaperEmail">
                                                        Correo electrónico de empresa
                                                    </label>
                                                    <input {...email} type="email" className="form-control spacing" id="whitePaperEmail" name="whitePaperEmail"
                                                           placeholder="Correo electrónico" required/>
                                                </div>
                                            </div>

                                            <div className="mt-5 col-md-12">
                                                <div className="form-group">
                                                    <div align="left">
                                                        <div className="checkbox icheck-primary spacing">
                                                            <input {...conditions} type="checkbox" id="whitePaperConditions" required/>
                                                            <label htmlFor="whitePaperConditions">
                                                                He leído y acepto las <a href={langBase + '/legal/privacidad'} target="_blank" className="text-tecalis">condiciones de privacidad</a> de Tecalis Signature.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {success && <div className="alert alert-success mt-2 animate__animated animate__fadeInDown" role="alert">
                                            <div align="center" className="font-alert spacing">Gracias. Te hemos enviado un correo con el link de descarga 🙂</div>
                                        </div>}

                                        {fail && <div className="alert alert-danger mt-2 animate__animated animate__fadeInDown" role="alert">
                                            <div align="center" className="font-alert spacing">Se ha producido un error. Inténtalo de nuevo 😔</div>
                                        </div>}

                                        <button id="btn-white-paper-send" className="btn btn-primary btn-sm mt-3 spacing" type="submit">
                                            Enviar
                                        </button>
                                    </form>
                                </div>

                                <div className="col-xl-1"/>

                                <div className="col-xl-5 contact-img">
                                    <img src={require('../../img/products/w-paper.svg').default} className="img-fluid mt-8" alt="..."/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalWhitePaper
